import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RouteKeys } from '../helper/route-keys.helper';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable()
export class ConfigService {
  maxAnzahlSorgeberechtigter: number = 0;
  anzahlBetreuungswuensche: any[] = [1, 2, 3];
  anzahlSorgeberechtigter: number = 2;

  private allKataloge: any[] = [];
  public kataloge: any[] = [];
  public katalogeMitKeineAntragstellung: any[] = [];
  public strassen: any[] = [];
  public betreuungsArten: any[] = [];
  public benutzer: any[] = [];
  public benutzerMitNiedrigererOderGleicherRolle: any[] = [];
  public globalSettings: any[] = [];
  public mandantName: string;
  public portalTitel: string;
  public bundesland: string;
  private statusKatalog: any[];

  get berechnungsDatum(): Date {
    return new Date();
  }

  private _lizenz: any;

  get lizenz(): any {
    if (this._lizenz === undefined || this._lizenz === null) {
      this._lizenz = this.storageService.getFromLocale('lizenzen');
    }
    return this._lizenz;
  }

  set lizenz(value: any) {
    if (this._lizenz !== value) {
      this.storageService.saveToLocale('lizenzen', value);
      this._lizenz = value;
    }
  }

  isInit: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private apiService: ApiService, private storageService: StorageService) {}

  init(): Observable<boolean> {
    this.load();
    return this.isInit.asObservable();
  }

  stop() {
    this.isInit.next(false);
  }

  reload(cb: () => void = null) {
    this.load(cb);
  }

  private load(cb: () => void = null) {
    this.apiService.post<any>(RouteKeys.Config.get_config, null).subscribe((response) => {
      this.lizenz = response.lizenz;
      this.noConfigFoundWarning = [];
      this.kataloge = [];
      this.katalogeMitKeineAntragstellung = [];
      this.allKataloge = response.kataloge;
      this.strassen = response.strassen;
      this.betreuungsArten = this.getKatalog('BetreuungsArtKatalog'); //response.betreuungsArten;
      this.benutzer = response.benutzer;
      this.benutzerMitNiedrigererOderGleicherRolle = response.benutzerMitNiedrigererOderGleicherRolle;
      this.maxAnzahlSorgeberechtigter = response.maxAnzahlSorgeberechtigter;
      this.globalSettings = response.globalSettings;
      this.mandantName = response.mandantName;
      this.getPasswordPolicy();
      this.isInit.next(true);
      this.portalTitel = this.getGlobalSetting('portalTitelDE');
      this.bundesland = response.lizenz.bundesland;
      this.statusKatalog = this.getKatalog('StatusKatalog');

      if (cb !== null) {
        cb();
      }
    });
  }

  public getTraegerForSelect() {
    return this.apiService.post<any>(RouteKeys.Traeger.get_traeger_select, null);
  }

  public getEinrichtungenForSelect() {
    return this.apiService.post<any>(RouteKeys.Einrichtung.get_einrichtungen_select, null);
  }

  public getEinrichtungenForDashboard() {
    return this.apiService.post<any>(RouteKeys.Einrichtung.get_einrichtungen_dashboard, null);
  }

  public getAlleEinrichtungenKurz() {
    return this.apiService.post<any>(RouteKeys.Einrichtung.get_alle_einrichtung_kurz, null);
  }

  public getEinrichtungenForExport() {
    return this.apiService.post<any>(RouteKeys.Einrichtung.get_einrichtungen_dashboard, null);
  }

  public getStrassen() {
    return this.strassen;
  }

  public getBenutzer() {
    return this.benutzer;
  }

  public getBenutzerMitNiedrigererOderGleicherRolle() {
    return this.benutzerMitNiedrigererOderGleicherRolle;
  }

  public getMandantName(): string {
    return this.mandantName;
  }

  noConfigFoundWarning: string[] = [];
  public getKatalog(typ: string) {
    const exist = this.kataloge[typ];
    if (exist !== null && exist !== undefined && exist.length > 0) {
      return exist;
    } else {
      const newKatalog = this.allKataloge.filter((x) => x.typ === typ && x.keineAntragstellung !== true);
      if (newKatalog !== null && newKatalog !== undefined && newKatalog.length > 0) {
        this.kataloge[typ] = newKatalog;
        return newKatalog;
      } else {
        if (!this.noConfigFoundWarning.find((x) => x === typ)) {
          this.noConfigFoundWarning.push(typ);
          console.warn('Katalog ' + typ + ' nicht gefunden!');
        }
      }
    }
  }

  public getKatalogMitKeineAntragstellung(typ: string) {
    const exist = this.katalogeMitKeineAntragstellung[typ];
    if (exist !== null && exist !== undefined && exist.length > 0) {
      return exist;
    } else {
      const newKatalog = this.allKataloge.filter((x) => x.typ === typ);
      if (newKatalog !== null && newKatalog !== undefined && newKatalog.length > 0) {
        this.katalogeMitKeineAntragstellung[typ] = newKatalog;
        return newKatalog;
      } else {
        if (!this.noConfigFoundWarning.find((x) => x === typ)) {
          this.noConfigFoundWarning.push(typ);
          console.warn('Katalog ' + typ + ' nicht gefunden!');
        }
      }
    }
  }

  public getGlobalSetting(param: string): string {
    if (param !== '' && param !== undefined) {
      const result = this.globalSettings.find((x) => x.key === param);
      if (result != null && result !== undefined) {
        return result.value;
      }
    }
    return '';
  }

  public getGlobalSettingAsNumber(param: string): number {
    const result = this.getGlobalSetting(param);
    if (!isNaN(Number(result))) {
      return Number(result);
    }
    return 0;
  }

  //-----------

  public rollen: any[] = [
    {
      id: 1,
      bezeichnung: 'Administrator',
      category: 'Verwaltung',
    },
    {
      id: 2,
      bezeichnung: 'Leitung',
      category: 'Verwaltung',
    },
    {
      id: 3,
      bezeichnung: 'Sachbearbeitung 1',
      category: 'Verwaltung',
    },
    {
      id: 4,
      bezeichnung: 'Sachbearbeitung 2',
      category: 'Verwaltung',
    },
    {
      id: 5,
      bezeichnung: 'Azubi',
      category: 'Verwaltung',
    },
    {
      id: 6,
      bezeichnung: 'Trägerverwaltung',
      category: 'Träger',
    },
    {
      id: 7,
      bezeichnung: 'Einrichtungsverwaltung',
      category: 'Einrichtung',
    },
    {
      id: 8,
      bezeichnung: 'Einrichtungsbearbeitung',
      category: 'Einrichtung',
    },
    {
      id: 9,
      bezeichnung: 'Trägerbearbeitung',
      category: 'Träger',
    },
  ];

  public antragsStatus: any[] = [
    {
      key: 0,
      value: 'Offen',
    },
    {
      key: 1,
      value: 'In Bearbeitung',
    },
    {
      key: 2,
      value: 'In Warteliste',
    },
    {
      key: 3,
      value: 'Vorgeschlagen',
    },
    {
      key: 6,
      value: 'Vorschlag angenommen',
    },
    {
      key: 7,
      value: 'Vorschlag abgelehnt',
    },
    {
      key: 4,
      value: 'Angenommen',
    },
    {
      key: 5,
      value: 'Abgelehnt',
    },
  ];

  public passwordPolicy: any = {
    text: '',
    minZiffern: '',
    minZiffernMessage: 'Es fehlen Ziffern!',
    minKleinBuchstaben: '',
    minKleinBuchstabenMessage: 'Es fehlen Kleinbuchstaben!',
    minGrossBuchstaben: '',
    minGrossBuchstabenMessage: 'Es fehlen Großbuchstaben!',
    minSonderzeichen: '',
    minSonderzeichenMessage: 'Es fehlen Sonderzeichen (.$@!%*?&#_-)!',
    minLaenge: '',
    minLaengeMessage: 'Es sind zu wenig Zeichen!',
    maxLaenge: '',
  };

  getPasswordPolicy() {
    const policy = this.getGlobalSetting('passwordPolicy');

    if (policy != null && policy != undefined && policy.length > 0) {
      const pswPatternArray = policy.split(',').map((n) => {
        return Number(n);
      });

      if (pswPatternArray[0] > 0) {
        this.passwordPolicy.minLaenge = '[A-Za-z\\d$@$!%*?&#_-]{' + pswPatternArray[0].toString() + ',}';
        this.passwordPolicy.text =
          '<br>Mindestens ' +
          pswPatternArray[0] +
          (pswPatternArray[5] >= pswPatternArray[0] ? ' und maximal ' + pswPatternArray[5] : '') +
          ' Zeichen lang.';
      }
      if (pswPatternArray[1] !== undefined && pswPatternArray[1] !== null && pswPatternArray[1] > 0) {
        this.passwordPolicy.minZiffern = '(?=([^0-9]*[0-9]){' + pswPatternArray[1].toString() + ',})';
        this.passwordPolicy.text = this.passwordPolicy.text.trim() + '<br>Mit mindestens ' + pswPatternArray[1] + ' Ziffer(n).';
      }
      if (pswPatternArray[2] !== undefined && pswPatternArray[2] !== null && pswPatternArray[2] > 0) {
        this.passwordPolicy.minKleinBuchstaben = '(?=([^a-z]*[a-z]){' + pswPatternArray[2].toString() + ',})';
        this.passwordPolicy.text = this.passwordPolicy.text.trim() + '<br>Mit mindestens ' + pswPatternArray[2] + ' Kleinbuchstabe(n).';
      }
      if (pswPatternArray[3] !== undefined && pswPatternArray[3] !== null && pswPatternArray[3] > 0) {
        this.passwordPolicy.minGrossBuchstaben = '(?=([^A-Z]*[A-Z]){' + pswPatternArray[3].toString() + ',})';
        this.passwordPolicy.text = this.passwordPolicy.text.trim() + '<br>Mit mindestens ' + pswPatternArray[3] + ' Großbuchstabe(n).';
      }
      if (pswPatternArray[4] !== undefined && pswPatternArray[4] !== null && pswPatternArray[4] > 0) {
        this.passwordPolicy.minSonderzeichen = '(?=(.*[$@$!%*?&#_-]){' + pswPatternArray[4].toString() + ',})';
        this.passwordPolicy.text = this.passwordPolicy.text.trim() + '<br>Mit mindestens ' + pswPatternArray[4] + ' Sonderzeichen (.$@!%*?&#_-).';
      }
      if (pswPatternArray[5] !== undefined && pswPatternArray[5] !== null && pswPatternArray[5] > 0) {
        this.passwordPolicy.maxLaenge = pswPatternArray[5];
      }

      this.passwordPolicy.text = this.passwordPolicy.text.trim() != '' ? '<br><b>Passwortrichtlinie:</b>' + this.passwordPolicy.text : '';
    } else {
      console.warn('Password policy nicht gefunden!');
    }
  }

  public monthsOfYear: any[] = [
    {
      key: 1,
      value: 'Januar',
    },
    {
      key: 2,
      value: 'Februar',
    },
    {
      key: 3,
      value: 'März',
    },
    {
      key: 4,
      value: 'April',
    },
    {
      key: 5,
      value: 'Mai',
    },
    {
      key: 6,
      value: 'Juni',
    },
    {
      key: 7,
      value: 'Juli',
    },
    {
      key: 8,
      value: 'August',
    },
    {
      key: 9,
      value: 'September',
    },
    {
      key: 10,
      value: 'Oktober',
    },
    {
      key: 11,
      value: 'November',
    },
    {
      key: 12,
      value: 'Dezember',
    },
  ];

  public daysOfMonth: any[] = [
    {
      key: 1,
      value: '1.',
    },
    {
      key: 2,
      value: '2.',
    },
    {
      key: 3,
      value: '3.',
    },
    {
      key: 4,
      value: '4.',
    },
    {
      key: 5,
      value: '5.',
    },
    {
      key: 6,
      value: '6.',
    },
    {
      key: 7,
      value: '7.',
    },
    {
      key: 8,
      value: '8.',
    },
    {
      key: 9,
      value: '9.',
    },
    {
      key: 10,
      value: '10.',
    },
    {
      key: 11,
      value: '11.',
    },
    {
      key: 12,
      value: '12.',
    },
    {
      key: 13,
      value: '13.',
    },
    {
      key: 14,
      value: '14.',
    },
    {
      key: 15,
      value: '15.',
    },
    {
      key: 16,
      value: '16.',
    },
    {
      key: 17,
      value: '17.',
    },
    {
      key: 18,
      value: '18.',
    },
    {
      key: 19,
      value: '19.',
    },
    {
      key: 20,
      value: '20.',
    },
    {
      key: 21,
      value: '21.',
    },
    {
      key: 22,
      value: '22.',
    },
    {
      key: 23,
      value: '23.',
    },
    {
      key: 24,
      value: '24.',
    },
    {
      key: 25,
      value: '25.',
    },
    {
      key: 26,
      value: '26.',
    },
    {
      key: 27,
      value: '27.',
    },
    {
      key: 28,
      value: '28.',
    },
    {
      key: 29,
      value: '29.',
    },
    {
      key: 30,
      value: '30.',
    },
    {
      key: 31,
      value: '31.',
    },
  ];

  hasLicense(key: string) {
    if (this._lizenz !== null && this._lizenz !== undefined) {
      for (var param in this._lizenz) {
        if (String(key) === param.trim() && this._lizenz[param] === true) {
          return true;
        }
      }
    }
    return false;
  }

  public getEinkommenRechtsgrundlagen(): any {
    // vorübergehend
    return [
      { id: 1, bezeichnung: 'EUR 2019' },
      { id: 2, bezeichnung: 'EUR 2020' },
      { id: 3, bezeichnung: 'EUR 2021' },
      { id: 4, bezeichnung: 'EUR 2022' },
    ];
  }

  public getStatusId(status: string) {
    var statusId = null;
    if (this.statusKatalog !== undefined && this.statusKatalog !== null && this.statusKatalog.length > 0) {
      statusId = this.statusKatalog.find((x) => x.bezeichnung === status).id;
    }
    return statusId;
  }

  public getKatalogBezeichnungByKey(katalog: string, key: number) {
    if (katalog !== null && katalog !== undefined) {
      const k = this.getKatalog(katalog);
      if (k !== undefined) {
        const el = k.find((x) => x.id === key);
        if (el !== undefined) {
          return el.bezeichnung;
        }
      }
    }
    return '';
  }

  // Enum für Beitragstypen
  public beitragTypes: any[] = [
    {
      key: 0,
      value: 'Elternbeitrag',
    },
    {
      key: 1,
      value: 'Essengeld (pauschal)',
    },
    {
      key: 2,
      value: 'Essengeld',
    },
    {
      key: 3,
      value: 'Getränkegeld',
    },
    {
      key: 4,
      value: 'Ferienbetreuung',
    },
    {
      key: 5,
      value: 'Servicepauschale',
    },
  ];

  private betreuungsForm: any[] = [
    // {
    //   key: null,
    //   value: 'Alle',
    // },
    {
      key: 1,
      value: 'KITA',
    },
    {
      key: 2,
      value: 'Offene Ganztagsschule',
    },
    {
      key: 3,
      value: 'Kindertagespflege',
    },
  ];

  public getBetreuungsFormen() {
    return this.betreuungsForm;
  }
}
